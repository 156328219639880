.team-member-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 6em;
  text-align: center;
}

.team-member-image {
  width: 5em;
  height: 5em;
  object-fit: cover;
  border-radius: 5em;
}

.team-member-name {
  font-family: var(--title-font);
  color: var(--on-section-colour-title);
  font-weight: 400;
}

.team-member-position {
  font-size: .9em;
  font-style: italic;
}