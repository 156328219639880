.project-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 20em;
  width: 15em;
  border: solid 1px var(--outline);
  text-decoration: none;
}

.project-card-image {
  height: 8em;
  object-fit: cover;
  max-width: 100%;
}

.project-card-content {
  padding: 0em 1em;
}