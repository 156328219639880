.project-descriptions {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  background-color: var(--secondary-section-colour);
  padding: var(--section-space) var(--horizontal-margin);
}

.image-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}

.span-image {
  grid-column: span 2;
}

.project-grid-image {
  width: 100%;
  height: 6em;
  align-self: flex-start;
  object-fit: cover;
}

.latest-news-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: var(--primary-section-colour);
  padding: var(--section-space) var(--horizontal-margin);
}

.trip-images-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
}

.trip-image {
  max-width: 5em;
}