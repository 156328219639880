.project-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  z-index: 10;
}

.project-arrows {
  display: flex;
  gap: .2em;
}

.arrow {
  max-width: 2em;
  max-height: 2em;
}

.arrow-disabled {
  opacity: .3;
}