.about-soma-salama-section {
  margin: var(--section-space) var(--horizontal-margin);
}

.about-naumbu-section {
  background-color: var(--secondary-section-colour);
  padding: var(--section-space) var(--horizontal-margin);
}

.team-section {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: var(--section-space) var(--horizontal-margin);
}

.team-members-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
}