.footer-container {
  display: flex;
  flex-direction: column;
  background-color: var(--footer-section-colour);
  padding: var(--section-space) var(--horizontal-margin);
  gap: 1em;
}

.contact {
  display: flex;
  gap: .5em;
  align-items: center;
}

.footer-icon {
  max-width: 1.5em;
  max-height: 1.5em;
}