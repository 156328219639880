.home-page-container {
  display: flex;
  flex-direction: column;
}

.hero-image {
  max-width: 100vw;
  min-width: 100vw;
  margin-top: 2em;
  max-height: var(--mobile-max-image-height);
  object-fit:cover;
}

.donate-section {
  display: flex;
  flex-direction: column;
  margin: var(--section-space) var(--horizontal-margin);
}

.mission-text {
  margin-bottom: calc(8*var(--spacex));
}

.section-image {
  max-height: 15em;
  object-fit: cover;
}

.button {
  padding: .3em .5em;
  border: solid 1.5px var(--medium-blue);
  border-radius: 2px;
  text-decoration: none;
  list-style-type: none;
  cursor: pointer;
  font-family: var(--title-font);
  font-size: .9em;
  font-weight: 500;
  color: var(--on-section-colour-title);
  background-color: transparent;
  outline: none;
  max-width: 8em;
  margin-bottom: var(--section-space);
  text-align: center;
}

.project-section {
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: var(--secondary-section-colour);
  padding: var(--section-space) var(--horizontal-margin);
}

.act-link {
  font-size: .8em;
}