@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root {

  /* Spacing */
  --horizontal-margin: 2em;
  --section-space: 2em;
  --spacex: 0.2em;
  --mobile-max-image-height: 12em;


  /* Fonts */
  --title-font: 'Poppins';
  --body-font: sans-serif;

  /* Colours */
  --white: #FFFFFF;
  --primary-section-colour: #F5F4F5;
  --on-section-colour-title: #515A60;
  --on-section-colour-text: #1F1E1E;
  --secondary-section-colour: #F9FBFC;
  --footer-section-colour: #efefef;
  --outline: #DFD2D1;
  --light-blue: #9FD3F2;
  --medium-blue: #202E4E;
  --dark-blue: #0D1931;
}

body {
  margin: 0;
  font-family: var(--title-font);
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-section-colour);
}

h1, h2, h3 {
  font-family: var(--title-font);
  color: var(--on-section-colour-title);
  margin: 0em;
}

h1 {
  font-size: 1.8em;
  font-weight: normal;
}

h2 {
  font-size: 1.5em;
  font-weight: normal;
}

h3 {
  font-weight: normal;
}

p {
  font-family:'Times New Roman', Times, serif;
  color: var(--on-section-colour-text);
  margin: 0em;
}

li {
  font-family: "Poppins";
  color: var(--on-section-colour-text);
  margin: 0em;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
