.navbar-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5em var(--horizontal-margin);
  background-color: var(--white);
  transition: 250ms;
  top: 0%;
  left: 0%;
  right: 0%;
  z-index: 1000;
}

.logo {
  max-width: 2.5em;
  max-height: 2.5em;
}

.nav-burger-menu {
  min-width: .7em;
  min-height: .7em;
  cursor: pointer;
}

.close-menu-button {
  max-width: .8em;
  max-height: .8em;
  cursor: pointer;
  margin-bottom: .5em;
}

.nav-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.5em;
  right: 0em;
  top: 0%;
  height: 100vh;
  text-align: right;
  background-color: var(--white);
  padding: 1em var(--horizontal-margin);
  transition: 250ms;
}

.nav-items-hidden {
  left: 120%;
  transition: 250ms;
}

.nav-item {
  text-decoration: none;
  list-style-type: none;
}